@import url('https://fonts.cdnfonts.com/css/glacial-indifference-2');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Glacial Indifference', sans-serif;
    background: transparent;
}

.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    text-align: center;
    color: black;
}

.playfair {
    font-family: 'Playfair Display', serif;
}

.glacial {
    font-family: "Glacial Indifference", sans-serif;
    font-weight: normal;
    font-style: normal;
}

.homePage {
    display: flex;
    flex-direction: column;
}

.homeDiv {
    display: flex;
    flex-direction: column;
    background: url('/src/images/Home.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: rgba(0,0,0,.75);
    background-blend-mode: multiply;
}

.homeTitleDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 2em;
}

.omcLogo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: clamp(6rem, 4.7727rem + 5.4545vw, 13.5rem);
}

.warning {
    margin: 15rem auto 1rem;
    font-size: clamp(0.75rem, 0.6273rem + 0.5455vw, 1.5rem);
    font-weight: bold;
    color: white;
}

.homeTitle {
    display: flex;
    flex-direction: column;
}

.summit {
    font-family: 'Playfair Display', serif;
    position: relative;
    font-size: clamp(2.5rem, 1.9273rem + 2.5455vw, 6rem);
    text-align: center;
    font-weight: bold;
    color: white;
    margin: 2.5em 0 0 0;
}

.homeSubtitle {
    font-size: clamp(1.2rem, 0.9055rem + 1.3091vw, 3rem);
    margin: 2.5em 0 0 0;
    color: white;
}

.fallSemester {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #202020;
    color: white;
    width: 60%;
    margin: 1em auto 0;
    font-family: 'Playfair Display', serif;
    font-size: clamp(1.6rem, 1.2727rem + 1.4545vw, 3.6rem);
}

@media (width < 1366px) {
    .fallSemester {
        width: 100%;
    }
}

.fallSemesterText {
    margin: 0 auto 1rem;
    font-size: .75em;
    width: 70%;
    font-weight: 100;
}

.registerNow {
    background-color: white;
    color: black;
    margin: 0;
    padding: 2vh 3vh;
    font-weight: 1000;
    font-size: clamp(0.8rem, 0.6364rem + 0.7273vw, 1.8rem);
}

.classesForFall {
    text-align: left;
    margin: 2vh 0 5vh 5vh;
    font-size: clamp(1.6rem, 1.1255rem + 2.1091vw, 4.5rem);
    font-family: 'Playfair Display', serif;
}

.homeLinksGrid {
    --grid-layout-gap: clamp(2rem, 1.5909rem + 1.8182vw, 4.5rem);
    display: grid;
    grid-gap: var(--grid-layout-gap);
    margin: 0 auto;

    /* --grid-column-count: 3;
    --grid-item--min-width: 16.875rem;
    --grid-item--max-width: 21.5625rem;
    --grid-item--width: clamp(16.875rem, 16.0942rem + 3.4703vw, 21.625rem);

    --gap-count: calc(var(--grid-column-count) - 1);
    --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap)); */
    /* --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count)); */
    
    /* grid-template-columns: repeat(auto-fill, 21.625rem); */
    /* grid-template-columns: repeat(auto-fill, minmax(270px, 345px)); */
    /* grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)); */
    /* width: clamp(16.875rem, 6.4983rem + 46.1187vw, 80rem); */
}

@media (max-width: 599px) {
    .homeLinksGrid {
        grid-template-columns: repeat(auto-fill, 16.875rem);
        width: 16.875rem;
    }

    .homeLinkTitles {
        line-height: 22.5rem;
    }
}

@media (min-width: 600px) {
    .homeLinksGrid {
        grid-template-columns: repeat(auto-fill, 18.75rem);
        width: 40.6431883rem;
    }

    .homeLinkTitles {
        line-height: 25rem;
    }
}

@media (min-width: 1200px) {
    .homeLinksGrid {
        grid-template-columns: repeat(auto-fill, 20rem);
        width: 67.54548rem;
    }

    .homeLinkTitles {
        line-height: 26.66625rem;
    }
}

@media (min-width: 1800px) {
    .homeLinksGrid {
        grid-template-columns: repeat(auto-fill, 21.625rem);
        width: 73.87504rem;
    }

    .homeLinkTitles {
        line-height: 28.833125rem;
    }
}

.homeLink {
    text-decoration: none;
}

.homeLinkTitles {
    text-align: right;
    color: white;
    font-size: 2rem;
    margin: 0;
}

.biblicalLink {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: rgba(0,0,0,.50);
}

.biblicalLink p {
    position: absolute;
    top: 0%;
    right: 0%;
    font-weight: 100;
}

.biblicalLink img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    mix-blend-mode: multiply;
}

.biblicalLink:hover {
    background-color: rgba(0,0,0,.7);
}

.registrationTitle {
    font-size: clamp(2rem, 1.8118rem + 0.8364vw, 3.15rem);
    font-weight: bold;
    background-color: none;
    margin-top: 1vh;
    margin-bottom: 2vh;
}

.registrationWholePageDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.registrationPage {
    display: flex;
    flex-direction: column;
    width: 60%;
}

@media (min-width: 1080px) {
    .registrationPage {
        width: 80%;
    }
}

@media (max-width: 768px) {
    .registrationPage {
        width: 100%
    }
}

.formDiv {
    width: 100%;
}


Form {
    display: flex;
    flex-direction: column;
    background-color: rgba(225, 225, 225, .75);
    border: .2vh solid black;
    align-items: center;
}

.dates {
    font-size: 1.8rem;
    text-align: left;
    margin: 1.8rem 0 0;
    width: 100%;
}

.formScheduleDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 1rem;
}

.formScheduleContent {
    text-align: left;
    width: fit-content;
}

.formAnswersDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%
}

.instructions{
    margin: 1rem 0;
    font-size: clamp(1rem, 0.9182rem + 0.3636vw, 1.5rem);
}

.biblicalTitle {
    text-align: left;
    margin-bottom: 0;
    font-size: clamp(1rem, 0.9182rem + 0.3636vw, 1.5rem);
    font-weight: bold;
}

.classTitles {
    text-align: left;
    margin-bottom: 0;
    font-weight: bold;
    font-size: clamp(1rem, 0.9345rem + 0.2909vw, 1.4rem);
}

.bibleClass {
    padding: .9rem;
    margin: 0;
    list-style: none;
    display: block;
    width: fit-content;
    text-align: left;
    font-size: clamp(1rem, 0.9182rem + 0.3636vw, 1.5rem);
}

.classesChecklist {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 2.5%;
}

@media (max-width: 1366px) {
    .classesChecklist {
        flex-direction: column;
    }
}

.classDiv {
    width: 20%;
    float: left;
}

@media (max-width: 1366px) {
    .classDiv {
        width: 100%;
    }
}

.otherClass {
    /* columns: 2; */
    padding: .9rem;
    margin: 0;
    list-style: none;
    float: left;
    text-align: left;
    font-size: clamp(1rem, 0.5424rem + 0.5360vw, 1.4rem);
}

.academicClassDiv{
    width: 20%;
    float: left;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1366px) {
    .academicClassDiv {
        width: 100%;
    }
}

input[type="checkbox"] {
    margin-right: .675rem;
}

.aboutPage {
    display: flex;
    flex-direction: column;
}

.item-a {
    grid-area: about;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: clamp(5.4rem, 4.5164rem + 3.9273vw, 10.8rem);
}

.item-b {
    grid-area: bar;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: .45rem solid white;
}

.item-c {
    grid-area: text;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5%;
    color: white;
    font-size: clamp(2rem, 1.5909rem + 1.8182vw, 4.5rem);
}

.aboutBanner {
    display: grid;
    grid-template-columns: 49.9% .2% 49.9%;
    grid-template-rows: clamp(10rem, -7.1608rem + 20.1005vw, 25rem) clamp(20rem, 8.5595rem + 13.4003vw, 30rem) clamp(10rem, -7.1608rem + 20.1005vw, 25rem);
    grid-template-areas: 
        ". . ."
        "about bar text"
        ". . .";
    background-color: rgba(0, 0, 0, .5);
    background-image: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url("/src/images/omcChurch.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.aboutLogo {
    position: absolute;
    width: clamp(7.5rem, 0.6357rem + 8.0402vw, 13.5rem);
    height: clamp(5rem, 0.4238rem + 5.3601vw, 9rem);
    z-index: 1;
    top: 0%;
    left: 48%;
}

@media (max-width: 1365px) {
    .aboutBanner {
        grid-template-columns: 100%;
        grid-template-rows: 40% 10% 40% 10%;
        grid-template-areas: 
            "about"
            "bar"
            "text"
            ".";
        background-image: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url("/src/images/omcChurchPhone.jpg");
    }

    .item-b {
        display: table;
        border-left: none !important;
        margin: 0 auto;
        width: 70%;
    }

    .item-b::after {
        border-top: .2rem solid white;
        content: '';
        display: block;
    }

    .aboutLogo {
        left: 40%
    }
}

.visionMission {
    width: 80%;
    background-color: rgba(225, 225, 225, .75);
    margin: clamp(2rem, 1.6727rem + 1.4545vw, 4rem) auto;
    padding: 0 2rem;
}

.visionMissionTitles {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0;
    font-size: clamp(2rem, 1.8364rem + 0.7273vw, 3rem);
}

.visionMissionText {
    text-align: left;
    width: 80%;
    font-size: clamp(1rem, 0.8364rem + 0.7273vw, 2rem);
}

@media (max-width: 768px) {
    .visionMissionText {
        width: 100%;
    }
}

.classInfoPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.classInfoBanner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    overflow: hidden;
    background-color: rgba(0,0,0,.7);
}

.classInfoBanners {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    mix-blend-mode: multiply;
}

.classInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(225,225,225,.75);
    font-size: clamp(1rem, 0.8691rem + 0.5818vw, 1.8rem);
    text-align: left;
    width: 90%;
    color: black;
    margin: 2rem auto 0;
    padding: 1% 8% 0 8%;
}

.classInfo p {
    margin: 0;
}

.classInfoClassesDiv {
    text-align: left;
    width: 90%;
    margin: 0 auto;
    padding: 0 0 0 8%;
}

.classInfoClasses {
    display: flex;
    flex-direction: column;
    font-size: clamp(1.2rem, 1.0691rem + 0.5818vw, 2rem);
}

.classInfoDivTitle {
    margin-top: clamp(1.5rem, 1.2545rem + 1.0909vw, 3rem);
    margin-bottom: 0;
}

.classInfoDivTitle li {
    list-style-type: none;
}

.classInfoClassesUl {
    padding: 0 0 0 1%;
    margin: 0;
    text-align: left;
    font-size: clamp(1.2rem, 1.0691rem + 0.5818vw, 2rem);
}

.classInfoClassesUl p {
    margin: clamp(0.2rem, 0.1509rem + 0.2182vw, 0.5rem) 0;
}

@media (max-width: 1365px) {
    .classInfo {
        width: 100%;
    }

    .classesInfoClassesDiv {
        width: 100%;
    }
}

.financialAid {
    font-size: clamp(1rem, 0.8691rem + 0.5818vw, 1.8rem);
    margin-bottom: clamp(1.5rem, 1.2545rem + 1.0909vw, 3rem);
}

.submitButton {
    /* width: 5.4rem; */
    font-size: clamp(1rem, 0.6727rem + 1.4545vw, 3rem);
    padding: clamp(0.5rem, 0.4182rem + 0.3636vw, 1rem);
    margin-bottom: 1.35rem;
    border: .2rem solid black;
}

.payment {
    text-align: left;
    padding: 0;
    margin-top: 0;
    margin-left: 1.5vh;
    font-size: clamp(1.1rem, 0.9855rem + 0.5091vw, 1.8rem);
}

.payment li {
    margin-bottom: .5vh;
    font-weight: bold;
}

.leftAlign {
    text-align: left;
}

.marginTop {
    margin-top: 1vh!important;
}

.staffPage {
    display: flex;
    flex-direction: column;
    padding-left: 5%;
}

.backToHomeDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Glacial Indifference";
}

.backToHomeDiv li{
    list-style-type: none;
    margin: 5rem 0 0;
}

.backToHomeDivOther {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Glacial Indifference";
}

.backToHomeDivOther li{
    list-style-type: none;
    margin: 2rem 0 0 1rem;
}

@media (max-width: 768px) {
    .backToHomeDiv {
        display: none;
    }

    .backToHomeDivOther {
        display: none;
    }
}

.backToHome {
    font-size: clamp(1.5rem, 0.9280rem + 0.6700vw, 2rem);
    color: black;
    text-decoration: none;
}

.staffPageTitle {
    text-align: left;
    color: #696969;
    font-size: clamp(3rem, 2.6727rem + 1.4545vw, 5rem);
    margin-top: 0;
}

.staffList {
    width: clamp(30rem, 27.5455rem + 10.9091vw, 45rem);
}

.staffBox {
    background-color: rgba(225, 225, 225, .75);
    margin-bottom: clamp(1rem, 0.8364rem + 0.7273vw, 2rem);
}

.staffMember {
    display: flex;
    flex-direction: row;
    padding: 1rem 0 1rem 3rem;
    align-items: center;
}

.staffImage {
    width: 20%;
}

.staffText {
    display: flex;
    flex-direction: column;
    width: 70%;
    padding-left: clamp(1.5rem, 1.2545rem + 1.0909vw, 3rem);
}

.staffName {
    font-weight: bold;
    font-size: clamp(1rem, 0.8364rem + 0.7273vw, 2rem);
    margin: 0 0 .5rem;
    text-align: left;
    font-family: 'Playfair Display', serif;
}

.staffTitle {
    font-size: clamp(0.75rem, 0.6273rem + 0.5455vw, 1.5rem);
    margin: 0;
    text-align: left;
    color: #696969;
}

@media (max-width: 1365px) {
    .staffPage {
        padding-right: 5%;
    }

    .staffList {
        width: 100%;  
    }

    .staffMember {
        padding: 1rem;
    }

    .staffText {
        width: 80%;
    }
}

.classesBanner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    overflow: hidden;
} 

.classesBanners {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
}

.biblicalInfoClasses {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
    color: black;
    /* padding: 0 5vh; */
    /* justify-content: center; */
    /* align-items: stretch; */
}

.biblicalInfoClasses li {
    list-style: none;
    text-align: left;
    font-size: 2vh;
    width: 100%;
    margin-right: 5%;
    background-color: #EFF0F0;
}

.biblicalInfoClasses li:last-child {
    margin-right: 0;
}

@media (max-width: 1365px) {
    .biblicalInfoClasses {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        padding: 0 1rem;
    }

    .biblicalInfoClasses li {
        width: 100%;
        margin: 0;
    }
}

.biblicalInfoClassesList {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.classesInfoTitle {
    color: #7B3F00;
    font-weight: 900;
}

.classesInfoDescription {
    width: 80%;
    font-family: "Glacial Indifference", "serif";
}

.differentClasses {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    column-gap: 1rem;
    width: 100%;
    margin-top: clamp(2rem, 1.5091rem + 2.1818vw, 5rem);
}

.otherInfoDiv {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 40%;
    background-color: #EFF0F0;
}

.otherInfoDiv p {
    font-size: 2vh;
    margin: 2vh;
}


@media (max-width: 1365px) {
    .differentClasses {
        flex-direction: column;
        align-items: stretch;
        row-gap: 1rem; 
    }

    .otherInfoDiv {
        width: 80%;
        margin: 1rem auto;
    }
}

.kindergartenInfoDiv {
    display: flex;
    flex-direction: column;
    margin-top: 1vh;
    width: 60%;
    text-align: left;
}

.peBanner {
    background-color: black;
    height: 30vh;
    text-align: center;
    vertical-align: middle;
    line-height: 30vh;
}

.burgerMenu {
    display: flex;
    flex-direction: row;
    position: fixed;
    width: clamp(6rem, 4.8545rem + 5.0909vw, 13rem);
    background-color: black;
    justify-content: space-evenly;
    top: 0%;
    right: 0%;
    z-index: 10;
    padding: clamp(0.5rem, 0.4182rem + 0.3636vw, 1rem);
}

.footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: black;
    margin-top: 10rem;
    align-items: center;
    padding: 3rem;
}

.footerImgDiv {
    width: 30%;
}

.footerImg {
    width: 60%;
}

.footerLinks {
    display: flex;
    flex-direction: column;
    color: white;
    text-align: left;
}

.footerLinks p {
    font-size: 1.5rem;
    margin: .75rem;
}

.footerLinks li {
    list-style-type: none;
    margin: .75rem;
}

@media (max-width: 1365px) {
    .footer {
        flex-direction: column;
    }

    .footerImgDiv {
        width: 100%;
    }
}

.footerCLinks {
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
}

/* MAP CSS TEMPORARY */
.map-h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 20px;
    padding-left: 10px;
    text-align: center;
  }
  
  .google-map {
    width: 100%;
    height: 60vh;
  }
  
  .pin {
    display: flex;
    align-items: center;
    width: 180px;
    color: var(--main-blue);
  }
  
  .pin-icon {
    font-size: 4rem;
  }
  
  .pin-text {
    font-size: 1.3em;
  }
  
  @media (min-width: 799px) {
    .google-map {
      height: 80vh;
    }
  
    .map-h2 {
      font-size: 1.3rem;
      font-weight: 400;
    }
  
    .pin {
      width: 15vw;
    }
  
    .pin-icon {
      font-size: 10vw;
    }
  }

  /* take out below after*/
.classScheduleBanner {
    background-color: black;
    width: 55%;
    position: absolute;
    right: 0%;
}

.classScheduleBanner p {
    color: white;
    margin: 3vh 0;
    text-align: center;
    font-size: clamp(1.5rem, 1.2545rem + 1.0909vw, 3rem);
}

@media (max-width: 1365px) {
    .classScheduleBanner {
        width: 100%;
    }
}

.waiverDiv {
    display: flex;
    flex-direction: column;
    width: 70%;
    background-color: white;
    border: .1em solid black;
    padding: 1em;
}

.staffContactUs {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.staffContactUs p {
    margin-top: .5rem;
}

/* burger menu css */
/* Position and sizing of burger button */
/* 
.bm-burger-button {
    width: 50% !important;
    height: 100%;
}

.bm-burger-button button{
    position: inherit !important;
    width: 100%;
    height: 100%;
}

.bm-burger-button span {
    display: flex;
    flex-direction: column;
    width: 50% !important;
} */

/* Color/shape of burger icon bars */
/* .bm-burger-bars {
    position: inherit !important;
    background: white;
    width: 50%;
    position: inherit !important;
} */

/* Color/shape of burger icon bars on hover*/
/* .bm-burger-bars-hover {
    background: #a90000;
} */

/* Position and sizing of clickable cross button */
/* .bm-cross-button {
    width: 1.5rem;
} */

/* Color/shape of close button cross */
/* .bm-cross {
    background: #bdc3c7;
} */

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
/* .bm-menu-wrap {
    position: fixed;
    height: 100%;
} */

/* General sidebar styles */
/* .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    list-style-type: none;
} */

/* Morph shape necessary with bubble or elastic */
/* .bm-morph-shape {
    fill: #373a47;
} */

/* Wrapper for item list */
/* .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
} */

/* Styling of overlay */
/* .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
} */
/* Individual item */
/* .bm-item {
    display: inline-block;
    color: #d1d1d1;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
} */
/* 
.bm-item:hover {
    color: #ffffff;
} */

/* bootstrap form css*/
.mb-3{margin-bottom:.5vh!important}
.form-check{display:block;min-height:1.5vh;padding-left:-2vh;margin-bottom:.125vh; text-align: left}
.row{display:flex;flex-wrap:wrap;margin-top:0; margin-left: 0; margin-right: 0;margin-bottom: 1vh;}
.col-form-label{margin-bottom:0;font-size:inherit;line-height:1.5vh}
.col-sm-2{flex:0 0 auto;width:100%;margin-top: .5vh;}
.form-label{margin-bottom: .5vh; font-size: 1.4vh; font-weight: bold; margin-right: .5vh;}
.form-control{display:block;width:100%;margin-top: .5vh;padding:.375vh .75vh;font-size:1.5vh;font-weight:400;line-height:1vh;color:black;background-color:white;background-clip:padding-box;border:.2vh solid #555; border-radius: .8vh}
.form-check-input{--bs-form-check-bg:white;width:2vh;height:1.5vh; vertical-align: middle; border:.2vh solid #dee2e6}
.offset-sm-2{margin-left:16.66666667%}
fieldset {padding: 0;margin: 0;border: 0;}